import { useState } from 'react';
import { useEffect } from 'react';

const SERVER_API_URL = "https://0anb55tas5.execute-api.eu-central-1.amazonaws.com/prod/quiz";

export default function App() {
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizSubjects, setQuizSubjects] = useState(new Map());
  const [selected, setSelected] = useState("");
  const [started, setStarted] = useState(false);
  const maxQuestionCount = 10;

  useEffect(() => {
    let ignore = false;
    fetch(SERVER_API_URL + "/summary")
      .then(response => response.json())
      .then(json => {
        if (!ignore) {
          const subjects = new Map(json.map(subject => [subject.id, subject.name_nl]));
          setQuizSubjects(subjects);
        }
      });
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (started) {
    let ignore = false;
    fetch(SERVER_API_URL + "/questions?selected=" + selected + "&count=" + maxQuestionCount)
      .then(response => response.json())
      .then(json => {
        if (!ignore) {
          json.forEach(q => {
            q.name_nl = quizSubjects.get(q.id);
          });
          setQuizQuestions(json);
        }
      });
    return () => {
      ignore = true;
    }};
  }, [quizSubjects, started, selected]);


  if (! started) {
    return <Start birds={quizSubjects} started={setStarted} setSelected={setSelected}/>
  }
  else {
    return <Quiz quizItems={quizQuestions}/>
  }
}

function Start({ birds, started, setSelected}) {
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  if (birds.size > selectedIndexes.length) {
    setSelectedIndexes(Array.from({length: birds.size}, () => false) )
  }

  function start() {
    if (checkMinimalSelection()) {
      started(true);
    }
  }

  function onOptionChange(e) {
    const index = e.target.value;
    const state = selectedIndexes.slice();
    state[index] = !state[index];
    setSelectedIndexes(state);
    const selection = [ ...birds].map((e, i) => state[i]? e[0]:"").filter(e => e !== "").join()
    setSelected(selection)
  }

  function selectAll(on) {
    setSelectedIndexes(Array.from({length: birds.size}, () => on) );
    const selection = [ ...birds].map(e => e[0]).join();
    setSelected(selection)
  }

  function checkMinimalSelection() {
    if (selectedIndexes.filter(v => v).length < 3) {
      setErrorMessage("Selecteer er minimaal 3!");
      return false;
    }
    else {
      return true;
    }
  }

  return (<div class="container mt-5">
    <p>Welkom bij de Vogelgeluidenquiz, de slimste manier om vogelgeluiden te leren herkennen!</p>
    <p>Selecteer welke vogels je in de quiz gaat horen: </p>
    <div class="my-3">
      {[ ...birds].map((e, i) => 
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value={i} id={e[0]} checked={selectedIndexes[i]} onChange={onOptionChange} />
            <label class="form-check-label" for="flexCheckDefault">
              {e[1]}
            </label>
          </div>
      )}
      <div class="form-check mt-3"><input class="form-check-input" type="checkbox" onChange={e => selectAll(e.target.checked)}/><small>alle/geen</small></div>
    </div>
    <ErrorMessage message={errorMessage}/>
    <p>{birds.size > 0 ? ( <button class="btn btn-outline-primary" onClick={start}>Start!</button> ) : ( <span>...</span> )}</p>
  </div>)
}

function ErrorMessage({ message }) {
  if (message) {
    return (<div class="alert alert-warning" role="alert">{message}</div>);
  }
  else {
    return "";
  }
}

function Quiz({ quizItems }) {
  const NOT_STARTED = -1;
  const ACTIVE = 0;
  const LAST_QUESTION = 1;
  const DONE = 2;

  const [quizState, setQuizState] = useState(NOT_STARTED);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  if (quizState == NOT_STARTED && quizItems.length) {
    setQuizState(ACTIVE);
  }

  function next() {
    const nextQuestion = currentQuestionIndex + 1;
    setCurrentQuestionIndex(nextQuestion);
    if (nextQuestion < quizItems.length) {
      if (nextQuestion == quizItems.length - 1) {
        setQuizState(LAST_QUESTION);
      }
    }
    else {
      setQuizState(DONE);
    }
  }

  const currentItem = quizItems[currentQuestionIndex]
  const buttonLabel = (quizState == ACTIVE)? "Volgende": "Afronden";

  if (quizState == NOT_STARTED) {
    return <p>Laden...</p>
  }
  else if (quizState != DONE) {
    return (
    <div class="container mt-5">
      <p>Vraag {currentQuestionIndex+1} / {quizItems.length}</p>
      <Question key={currentQuestionIndex} item={currentItem} advance={next}/>
      <button class="btn btn-outline-primary" onClick={next}>{buttonLabel}</button>
    </div>
    )
  }
  else {
    return (    
       <div class="container mt-5">
        <Result value={quizItems}/>
       </div>
    )
  };
}

function Question({ item, advance }) {
  item.answer = ""
  function setInput(value) {
    if (value) {
      item.answer = value.trim();
    }
  }

  function next(value) {
    if (value) {
      advance();
    }
  }

  return (
    <div class="card bg-light">
      <div class="card-body">
        <audio controls autoPlay>
          <source src={item.soundUrl} type="audio/mpeg" />
        </audio>
        <div class="row">
          <div class="col-md-8">
            <input type="text"
              placeholder="Vogelnaam?" 
              autoFocus 
              class="form-control" 
              onInput={e => setInput(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  next(e.target.value);
                }
              }}>
            </input>
          </div>
        </div>
      </div>
    </div>
  );
}

function Result({ value }) {
  var correct = 0;
  var wrong = 0;
  var results = [];

  value.forEach(question => {
    if (question.answer && question.answer.toLowerCase() === question.name_nl.toLowerCase()) {
      correct++;
      results.push(<li key={question.id}>{question.answer} <span class="badge rounded-pill bg-success">Goed!</span></li>)
    }
    else {
      wrong++;
      results.push(<li>{question.answer} <span class="badge rounded-pill bg-danger">Fout</span> (moest zijn: {question.name_nl})</li>)
    }
  })

  return (
    <>
    <p>Je had er {correct} goed!</p>
    <ol>{results}</ol>
    </>
    )
}
